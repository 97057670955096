<template>
  <v-data-table
    :headers="headers"
    :items="arPositions"
    class="dt-listing transparent"
    disable-pagination
    disable-sort
    hide-default-footer
  >
    <template #top>
      <v-toolbar color="grey lighten-2" flat>
        <v-row align="center" justify="center">
          <v-col cols="3">
            <accounting-movement-type-select
              v-model="value.accounting_movement_type_id"
              :disabled-items="disabledMovementTypeList"
              required
            />
          </v-col>
          <v-col cols="3">
            <accounting-description-select
              :value="sConfig"
              label="accounting.description"
              required
              @change="onSelectConfig"
            />
          </v-col>
          <v-col cols="3">
            <accounting-book-select
              :value="sBook"
              label="accounting.book"
              required
              @change="onSelectBook"
            />
          </v-col>
          <v-col class="text-end" cols="3">
            <v-btn
              :disabled="!valid"
              class="ml-4"
              color="primary"
              depressed
              text
              @click="onAddEmptyItem"
            >
              <icon-add class="mr-2" />
              {{ $t("add.line") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>

    <template #body="data">
      <draggable
        :list="arPositions"
        handle=".v-btn.handler"
        tag="tbody"
        @change="onReorder"
      >
        <template v-for="(item, index) in data.items">
          <accounting-entry-position-item
            :key="`accpi.${index}`"
            :book="sBook"
            :config="sConfig"
            :headers="headers"
            :item="item"
            :row="index"
            @delete="onRemoveItem"
          />
        </template>
      </draggable>
    </template>
    <template #item="{ headers, item, index }">
      <accounting-entry-position-item
        :book="sBook"
        :config="sConfig"
        :headers="headers"
        :item="item"
        :row="index"
        @delete="onRemoveItem"
      />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import {
  AccountingEntry,
  AccountingEntryPositionData,
} from "@planetadeleste/vue-mc-gw";
import AccountingMovementTypeSelect from "@/modules/accountingentries/components/AccountingMovementTypeSelect.vue";
import {
  cloneDeep,
  forEach,
  get,
  isNil,
  isString,
  last,
  set,
  sortBy,
} from "lodash";
import AccountingEntryPositionItem from "@/modules/accountingentries/components/AccountingEntryPositionItem.vue";
import { Callback, PartialNullable } from "@/types/utils";
import AccountingBookSelect from "@/modules/accountingentries/components/AccountingBookSelect.vue";
import AccountingDescriptionSelect from "@/modules/accountingentries/components/AccountingDescriptionSelect.vue";
import Draggable from "vuedraggable";
import { array, boolean, object, string } from "yup";
import { type ValidationError } from "yup";
import { EventBus } from "@/services/event-bus";

interface DragMove {
  element: PartialNullable<AccountingEntryPositionData>;
  newIndex: number;
  oldIndex: number;
}

interface DragMoveEvent {
  moved: DragMove;
}

@Component({
  components: {
    AccountingDescriptionSelect,
    AccountingBookSelect,
    AccountingEntryPositionItem,
    AccountingMovementTypeSelect,
    Draggable,
  },
})
export default class AccountingEntryPositionTable extends Mixins(
  DataTableMixin
) {
  @Prop(Object) readonly value!: AccountingEntry;
  @Prop(Array) readonly disabledMovementTypeList!: number[];
  @Prop(Boolean) readonly valid!: boolean;

  sConfig = "";
  sBook = "";

  get arPositions(): PartialNullable<AccountingEntryPositionData>[] {
    return sortBy(this.positions, ["sort_order"]);
  }

  get positions(): PartialNullable<AccountingEntryPositionData>[] {
    return this.value && this.value instanceof AccountingEntry
      ? this.value.get("positions", [])
      : [];
  }

  async onAddEmptyItem() {
    const arPositionList = [...this.positions];

    if (arPositionList.length) {
      const valid = await this.onValidatePositions();
      if (!valid) {
        return;
      }
    }

    const obPosition: PartialNullable<AccountingEntryPositionData> = {
      sort_order: arPositionList.length + 1,
      columns: [{ item_type: "code", sort_order: 1 }],
      credit: null,
      debit: null,
      is_positive: null,
      // @ts-ignore
      config: this.sConfig,
      book: this.sBook,
    };
    arPositionList.push(obPosition);

    this.value.set("positions", arPositionList);
  }

  async cleanPositionColumns(
    obItem: PartialNullable<AccountingEntryPositionData>
  ) {
    if (!obItem.columns || obItem.columns.length === 1) {
      return;
    }

    const obColumn = last(obItem.columns);
    const index = obItem.columns.length - 1;
    const valid = await this.getColumnValidationSchema().isValid(obColumn);

    if (!valid) {
      obItem.columns.splice(index, 1);

      if (obItem.columns.length > 1) {
        await this.cleanPositionColumns(obItem);
      }
    }
  }

  onRemoveItem(index: number) {
    if (index <= -1 || !this.value) {
      return;
    }

    const arPositionList = [...this.arPositions];
    arPositionList.splice(index, 1);
    this.value.set("positions", arPositionList);
  }

  onReorder(obData: DragMoveEvent) {
    const arPositionList = [...this.positions];
    const { newIndex, oldIndex } = obData.moved;
    arPositionList.splice(newIndex, 0, arPositionList.splice(oldIndex, 1)[0]);
    forEach(arPositionList, (obItem, index) => {
      obItem.sort_order = index + 1;
    });
    this.value.set("positions", arPositionList);
  }

  getColumnValidationSchema() {
    const sColumnsMessage = this.$t("required.accounting.subs") as string;
    return object().shape(
      {
        field: string().when("code", {
          is: (value: string | null) => isNil(value),
          then: (schema) => schema.required(sColumnsMessage),
          otherwise: (schema) => schema.nullable(),
        }),
        code: string().when("field", {
          is: (value: string | null) => isNil(value),
          then: (schema) => schema.required(sColumnsMessage),
          otherwise: (schema) => schema.nullable(),
        }),
      },
      [["field", "code"]]
    );
  }

  async onValidatePositions(alert = true) {
    if (!this.positions.length) {
      return false;
    }

    const arPositionList = [...this.positions];
    let sMessage: string | null | undefined = null;

    await arPositionList.reduce(async (before, obPosition) => {
      const obPositionCloned = cloneDeep(obPosition);
      await this.cleanPositionColumns(obPositionCloned);
      await before;
      const sResponse = await this.onValidatePosition(obPositionCloned);

      if (isNil(sMessage) && isString(sResponse)) {
        sMessage = sResponse;
      }
    }, Promise.resolve());

    if (sMessage) {
      if (alert) {
        this.$toast.error(sMessage);
      }

      return false;
    }

    return true;
  }

  async onValidatePosition(
    obItem: PartialNullable<AccountingEntryPositionData>
  ): Promise<string | boolean> {
    const sDebitCreditMessage = this.$t(
      "required.accounting.credit.or.debit"
    ) as string;
    const obItemSchema = object().shape(
      {
        columns: array()
          .label(this.$t("subaccounting") as string)
          .of(this.getColumnValidationSchema()),
        is_positive: boolean()
          .required()
          .label(this.$t("accounting.sign") as string),
        credit: string().when("debit", {
          is: (value: string) => isNil(value),
          then: (obSchema) => obSchema.required(sDebitCreditMessage),
          otherwise: (obSchema) => obSchema.nullable(),
        }),
        debit: string().when("credit", {
          is: (value: string) => isNil(value),
          then: (obSchema) => obSchema.required(sDebitCreditMessage),
          otherwise: (obSchema) => obSchema.nullable(),
        }),
      },
      [["credit", "debit"]]
    );

    try {
      await obItemSchema.validate(obItem);
      return true;
      // @ts-expect-error
    } catch (err: ValidationError) {
      return err.errors[0];
    }
  }

  onSelectConfig(sValue: string) {
    if (isNil(sValue)) {
      return;
    }

    this.sConfig = sValue;

    if (!this.positions.length) {
      return;
    }

    this.onSetPositionsValue();
  }

  onSelectBook(sValue: string) {
    if (isNil(sValue)) {
      return;
    }

    this.sBook = sValue;

    if (!this.positions.length) {
      return;
    }

    this.onSetPositionsValue();
  }

  onSetPositionsValue() {
    const arPositionList = [...this.positions];
    forEach(arPositionList, (obPosition) => {
      set(obPosition, "config", this.sConfig);
      set(obPosition, "book", this.sBook);
    });

    this.value.set("positions", arPositionList);
  }

  created() {
    EventBus.on("validate.accounting.entry", (fnSave: Callback) => {
      this.onValidatePositions().then((valid) => {
        if (valid) {
          fnSave();
        }
      });
    });
  }

  beforeDestroy() {
    EventBus.off("validate.accounting.entry");
  }

  mounted() {
    if (this.value?.positions && this.value.positions.length) {
      const obPosition = this.value.positions[0];
      this.sConfig = get(obPosition, "config", "") as string;
      this.sBook = get(obPosition, "book", "") as string;

      if (this.sConfig !== "" && this.sBook !== "") {
        this.onSetPositionsValue();
      }
    }

    this.headers = [];
    const arHeaders: DataTableHeader[] = [
      { text: "order", value: "reorder", sortOrder: 1 },
    ];

    for (let i = 1; i <= 5; i++) {
      arHeaders.push({
        text: this.$t("accounting.sub.n", { n: i }) as string,
        value: `column_${i}`,
        translated: true,
      });
    }

    // arHeaders.push({
    //   text: "accounting.description",
    //   value: "description",
    //   cellClass: "w-max-200",
    // });
    arHeaders.push({ text: "accounting.sign", value: "is_positive" });
    arHeaders.push({ text: "accounting.debit", value: "debit" });
    arHeaders.push({ text: "accounting.credit", value: "credit" });
    // arHeaders.push({ text: "accounting.book", value: "book" });

    this.setDTHeaders(arHeaders).addDTActionsHeader();
  }
}
</script>
